<template>
  <div>
    <Slides v-bind:scrollingCards="scrollingCards" />

    <v-card class="no-overflow tertiary" >
      <v-layout align-center justify-center fill-height class="text-container">
        <v-row no-gutters justify="center">
          <v-col order="1" order-md="2" cols="10" md="5" lg="5" xl="5">
            <v-card
              align-center
              justify-center
              class="elevation-0 mt-4 mb-2 my-sm-8 pa-4"
              color="transparent"
            >
              <v-card-title
                class="contrast--text text-no-wrap text-size-2 font-weight-light"
                v-html="$t('contacts.slide2.name')"
              >
              </v-card-title>
              <v-row no-gutters class="px-4 py-2" align="center">
                <span
                  class="justify-left contrast--text text-no-wrap text-size-3 font-weight-light"
                  v-html="$t('contacts.slide2.phone1')"
                >
                </span>
                <v-icon class="ml-2 mr-1" color="contrast">mdi-whatsapp</v-icon>
                <v-icon class="mx-1" color="contrast">mdi-telegram</v-icon>
              </v-row>
              <v-card-text
                class="py-2 contrast--text text-no-wrap text-size-3 font-weight-light"
                v-html="$t('contacts.slide2.phone2')"
              >
              </v-card-text>
              <v-row no-gutters class="px-4 py-2" align="center">
                <span
                  class="justify-left contrast--text text-no-wrap text-size-3 font-weight-light"
                  v-html="$t('contacts.slide2.email')"
                ></span>
                <a
                class="py-2 contrast--text text-no-wrap text-size-3 font-weight-light"
                  style="text-decoration: none;"
                  href="mailto:alex.vecchiettini@gmail.com"
                  >alex.vecchiettini@gmail.com</a
                >
              </v-row>
              <v-row no-gutters class="px-4 py-2 ma-0" align="center">
                <span
                  class="justify-left contrast--text text-no-wrap text-size-3 font-weight-light"
                  v-html="$t('contacts.slide2.social')"
                >
                </span>
                <v-icon
                  onclick="window.open('https://www.linkedin.com/in/alex-vecchiettini')"
                  class="mx-2"
                  color="contrast"
                  >mdi-linkedin</v-icon
                >
              </v-row>
            </v-card>
          </v-col>

          <v-col order="2" order-md="1" cols="10" md="5">
            <v-card
              align-center
              justify-start
              class="elevation-0 mt-2 mb-4 my-md-16 px-8"
              color="transparent"
            >
              <v-img
                height="35vh"
                justify="center"
                width="35vh"
                src="@/assets/Penguins_will_bite.webp"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-card>
  </div>
</template>

<style>
.break-line {
  overflow-wrap: break-word !important;
  white-space: normal !important;
}
</style>

<script>
import Slides from "@/components/Slides";

export default {
  name: "contacts",

  components: { Slides },

  data: () => ({
    // Description of the Cards to show in the page
    scrollingCards: [
      {
        id: "1",
        class: "secondary",
        layoutColor: "",
        height: "50vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: true,
          cols: 10,
          md: 10,
          lg: 10,
          xl: 10,
          textCardContainerClasses: "elevation-0",
          textCardContainerColor: "transparent",
          title: "contacts.slide1.title",
          titleClasses: "text-no-wrap text-left primary--text text-size-1 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
    ],
  }),
};
</script>
